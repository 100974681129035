:root {
  --ws-text-color-default: #333333;
  --ws-text-color-gray: #999999;
  --ws-text-color-blue: #337ab7;
  --ws-text-color-white: #ffffff;
}

.test {
  color: var(--color-primary) !important;
}
